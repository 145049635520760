import axios from "axios";
import { Role } from "../../context_providers/auth/user_model";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  getHeaders,
  toQueryString,
} from "../api_client";

// ${process.env.REACT_APP_BACKEND_BASE_URL}
export const GetRolesApi = async (option: {
  count?: number;
  page?: number;
  all?: boolean;
}) => {
  try {
    let url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/roles${toQueryString(option)}`;

    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }

  // const now = new Date()
  // const response: { success: boolean, data: RoleModel[] } = {
  //     success: true,
  //     data: [
  //         {
  //             id: 1,
  //             name: "Super admin",
  //             permissions: [
  //                 {
  //                     module: MODULE.USER,
  //                     action: [ACTION.CREATE,ACTION.READ,ACTION.UPDATE,ACTION.DELETE, ACTION.ALL]
  //                 },
  //                 {
  //                     module: MODULE.ORDER,
  //                        action: [ACTION.CREATE,ACTION.READ,ACTION.UPDATE,ACTION.DELETE, ACTION.ALL]
  //                 },
  //                 {
  //                     module: MODULE.ROLE,
  //                        action: [ACTION.CREATE,ACTION.READ,ACTION.UPDATE,ACTION.DELETE, ACTION.ALL]
  //                 }
  //             ]
  //         }
  //     ]

  // }
  // return new Promise<{ data?: any, success: boolean, error?: string }>((resolve) =>
  //     setTimeout(() => resolve(response), 2000)
  // );
};

export const GetRoleApi = async (id: number) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/roles/${id}`;
    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const CreateRoleApi = async (roleData: Role) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/roles/`;
    const res = await fetchPost(url, roleData);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const UpdateRoleApi = async (roleData: Role) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/roles/${roleData.id}`;
    const res = await fetchPut(url, roleData);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const DeleteRoleApi = async (id: number | string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/roles/${id}`;
    const res = await fetchDelete(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetRolesOfEmpApi = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/roles/emp/${id}`;

    const res = await fetchGet(url);
    return res ;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
export const GetOrgsForRoleApi = async (option: { search?: string }) => {
  try {
    let url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/roles/orgs${toQueryString(option)}`;

    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
