import { FunctionComponent } from "react";

interface HrmSelectProps {
  name: string;
  label: string;
  defaultValue?: string;
  labelClass?: string;
  inputClass?: string;
  disabled?: boolean;
  options: string[];
  required: boolean;
  onChange: (e: any) => void;
}

const HrmSelect: FunctionComponent<HrmSelectProps> = ({
  name,
  label,
  defaultValue,
  labelClass,
  inputClass,
  disabled,
  options,
  required,
  onChange,
}) => {
  return (
    <div className="">
      <div className={`text-sm text-gray-400 font-semibold ${labelClass} `}>
        {label}
      </div>
      {disabled ? (
        <div className="">{defaultValue}</div>
      ) : (
        <select
          className={`  shadow-md py-3 px-4 bg-white w-full  focus:outline-none rounded-lg text-black font-semibold mt-1 cursor-pointer  ${inputClass} `}
          name={name}
          defaultValue={defaultValue}
          required={required}
          onChange={(e) => onChange(e)}
          disabled={disabled}
        >
          <option value="" hidden>
            Select
          </option>
          {options?.length > 0 &&
            options.map((el, i) => {
              return <option value={el}>{el}</option>;
            })}
        </select>
      )}
    </div>
  );
};

export default HrmSelect;
