/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from "@material-ui/core";
import {
  mdiAttachment,
  mdiBookCheck,
  mdiClose,
  mdiFileClock,
  mdiMicrosoftExcel,
  mdiSwapVertical,
  mdiTextBoxCheckOutline,
  mdiTextBoxMultiple,
  mdiTools,
  mdiViewColumn,
} from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { Complaintmodel } from "../../../../service/models/complaint_model";
import { getComplaints } from "../../../../service/repos/support/complaint_repo";
import {
  complaintStatus,
  complaint_by,
  complaint_related_to,
  PriorityType,
  SubDepartments,
  SourceType,
  Departments,
  ComplaintTicketType,
} from "../../../../utils/enums";
import { stateData } from "../../../../utils/states_data";
import { urls } from "../../../../utils/urls";

import LoadingWidget from "../../../components/loading_spinner";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../new_components/common/drawer_modal";
import FilterDropDown from "../../../new_components/common/filter_drop_down";
import ModalDialog from "../../../new_components/common/modal";
import Pagination from "../../../new_components/common/pagination";
import EmployeeSearchWidget from "../../../new_components/emp_search_widget";
import SearchFieldWidget from "../../../new_components/search_field";
import AttachmentWidget from "../detail/components/attaments_widget";
import CommentWidget from "./components/comment_widget";
import ExportComplaints from "./components/export_widget";
import HistoryWidget from "./components/history_widget";
import { UTCToLocalDate } from "../../../../utils/date_util";
import EmployeeMultiSearchWidget from "../../../new_components/emp_multi_search_widget";
import { UserModel } from "../../../../context_providers/auth/user_model";
import OrgSearchField from "./components/organizationSeach";
import { useAuth } from "../../../../context_providers/auth/authProvider";
export interface ComplaintFilterModle {
  search?: string;
  page: number;
  count: number;
  all?: any;
  filterData?: {
    [key: string]: any;
    fromDate?: Date;
    toDate?: Date;
    complaintBy?: string[];
    complainRelatedTo?: string[];
    responsiblePerson?: string[];
    created_by_name?: string[];
    createdByDept?: string[];
    emp_name?: string;
    emp_names?: string;
    status?: string[];
    state?: string[];
    org?: string | string[];
    ticket_type?: string[];
    res_dep?: string[];
    pre_post?: string[];
    priority?: string[];
    source?: string[];
    active?: boolean;
    sort_by?: string;
    sort_order?: string;
  };
}
// const now = new Date();
// const start_date = new Date(new Date(now).setDate(now.getDate() - 30));
// const end_date = now;

export const defaultFilter: ComplaintFilterModle = {
  page: 1,
  count: 100,
  filterData: {
    active: true,
    sort_by: "complaint_date",
    sort_order: "desc",
    org: "",
    // fromDate: start_date,
    // toDate: end_date,
  },
};

interface ComplaintListingPageProps {}

type TicketCount = {
  ticket_type: string;
  ticket_count: number;
};

type ColumnKeys = keyof typeof initialColumnVisibility;

const initialColumnVisibility = {
  date: true,
  dueDate: true,
  ago: true,
  complaintId: true,
  deviceId: true,
  org: true,
  responsiblePerson: true,
  priority: true,
  status: true,
  visibility: true,
  complaintBy: true,
  source: true,
  createdBy: true,
  createdByDept: true,
  complaintRelatedTo: true,
  prePostDelivery: true,
  responsibleDepartment: true,
  complainantName: true,
  complainantMobile: true,
  complainantAddress: true,
  nextActionDate: true,
  subject: true,
  description: true,
};

const ComplaintListingPage: FunctionComponent<
  ComplaintListingPageProps
> = () => {
  const { showToast } = useToast();
  const tempFilter = localStorage.getItem("com_filter");
  // const [emp, setEmp] = useState<EmployeeModel>();
  const [filter, setFilter] = useState<ComplaintFilterModle>(
    tempFilter ? JSON.parse(tempFilter) : defaultFilter
  );
  const [showPopUp, setShowPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [analytic, setAnalytic] =
    useState<{ status: string; count: number }[]>();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(""); // Add this line
  const [averageTime, setAverageTime] = useState<{
    cur_month: [
      {
        count: number;
        days: number;
      }
    ];
    last_month: [
      {
        count: number;
        days: number;
      }
    ];
    all_days: [
      {
        count: number;
        days: number;
      }
    ];

    ticket_type_cur_month: [
      {
        count: number;
        days: number;
      }
    ];
    ticket_type_last_month: [
      {
        count: number;
        days: number;
      }
    ];
    ticket_type_all_days: [
      {
        count: number;
        days: number;
      }
    ];
  }>();
  const [complaints, setComplaints] = useState<Complaintmodel[]>([]);
  const [showHistory, setShowHistory] = useState("");
  const [showComment, setShowComment] = useState("");
  const [ticketType, setTicketType] = useState<TicketCount[]>([]);
  const [showAttachments, setShowAttachments] = useState<Complaintmodel>();
  const [showExport, setShowExport] = useState(false);
  const [overDue, setOverDue] = useState({ all: 0, today: 0 });
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const savedVisibility = localStorage.getItem("columnVisibility");
    return savedVisibility
      ? JSON.parse(savedVisibility)
      : initialColumnVisibility;
  });
  const [selectedEmployees, setSelectedEmployees] = useState<UserModel[]>([]);

  const handleSelect = (emp: UserModel) => {
    setSelectedEmployees((prev) => {
      // Check if the employee is already selected
      const isAlreadySelected = prev.some(
        (selectedEmp) => selectedEmp.uid === emp.uid
      );

      // If not already selected, add it
      if (!isAlreadySelected) {
        return [...prev, emp];
      }
      // If already selected, return the previous state
      return prev;
    });

    // Update the filter as needed, ensuring you're not adding duplicates
    setFilter((old) => ({
      ...old,
      filterData: {
        ...old.filterData,
        created_by_name: [
          ...new Set([...(old.filterData?.created_by_name || []), emp.uid!]),
        ],
        emp_names: [
          ...new Set([
            ...(old.filterData?.emp_names
              ? old.filterData.emp_names.split(", ")
              : []),
            emp.first_name + " " + emp.last_name,
          ]),
        ].join(", "),
      },
    }));
  };

  const handleDelete = (uid: string) => {
    setSelectedEmployees((prev) => {
      // Filter out the deleted employee
      const updatedEmployees = prev.filter((emp) => emp.uid !== uid);

      // Update the filter to remove the employee from created_by_name and emp_name
      setFilter((old) => ({
        ...old,
        filterData: {
          ...old.filterData,
          created_by_name: old.filterData?.created_by_name?.filter(
            (id) => id !== uid
          ),
          emp_names: old
            .filterData!.emp_names!.split(", ")
            .filter(
              (name) =>
                name !==
                `${prev.find((emp) => emp.uid === uid)?.first_name} ${
                  prev.find((emp) => emp.uid === uid)?.last_name
                }`
            )
            .join(", "),
        },
      }));

      return updatedEmployees;
    });
  };

  const handleClearAll = () => {
    setSelectedEmployees([]);
  };

  const toggleColumnVisibility = (key: any) => {
    setColumnVisibility((prev: any) => {
      const newVisibility = { ...prev, [key]: !prev[key] };
      localStorage.setItem("columnVisibility", JSON.stringify(newVisibility));
      return newVisibility;
    });
  };

  const fetchComplaints = async () => {
    setLoading(true);

    let org: string | undefined = "";
    if (
      user?.emp_profile?.permissions &&
      Array.isArray(user.emp_profile.permissions) &&
      user.emp_profile.permissions.length
    ) {
      org = user.emp_profile.permissions[0].org;
    }

    let finalFilter = { ...filter };

    const res = await getComplaints(filter);
    console.log(res);

    if (res.success) {
      setComplaints(res.data.complaints);
      if (res.ticketTypeComplaints) {
        setTicketType(res.ticketTypeComplaints);
      }
      if (res.data.metadata[0]) setTotal(res.data.metadata[0].total);
      setAnalytic(res.analytic);
      if (res.average_time) setAverageTime(res.average_time);
      if (res.over_due) setOverDue(res.over_due);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  const handleSortDate = () => {
    const sort_order =
      filter.filterData?.sort_order === "desc" ? "asc" : "desc";
    setFilter((o) => ({
      ...o,
      filterData: { ...o.filterData, sort_order },
    }));
  };

  const { user } = useAuth();
  let userHasOrg = false;

  // useEffect(() => {
  // });

  useEffect(() => {
    if (
      user?.emp_profile?.permissions &&
      Array.isArray(user.emp_profile.permissions) &&
      user?.emp_profile?.permissions.length
    ) {
      userHasOrg = true;
      setSelectedOrg(user.emp_profile.permissions[0].org ?? selectedOrg);
      console.log("set selected org to:", user.emp_profile.permissions[0].org);
    }

    localStorage.setItem("com_filter", JSON.stringify(filter));

    const cf = localStorage.getItem("cf");
    const now = new Date();

    if (
      !cf ||
      moment(parseInt(cf)).isBefore(
        new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)
      )
    )
      setShowPopUp(true);
    fetchComplaints();
  }, [filter]);

  // useEffect(() => {
  //   if (emp) {
  //     setFilter((o) => ({
  //       ...o,
  //       filterData: {
  //         ...o.filterData,
  //         responsiblePerson: [
  //           ...(o.filterData!.responsiblePerson
  //             ? o.filterData!.responsiblePerson
  //             : []),
  //           emp.uid!,
  //         ],
  //       },
  //     }));
  //   } else {
  //     setFilter({
  //       ...filter,
  //       filterData: {
  //         ...filter.filterData,
  //         responsiblePerson: undefined,
  //       },
  //     });
  //   }
  // }, [emp]);
  // const exportData = async () => {
  //   setLoading(true);
  //   const res = await getComplaints({ ...filter, all: true });
  //   if (!res.success) {
  //     setLoading(false);
  //     return;
  //   }
  //   const options = {
  //     fieldSeparator: ",",
  //     quoteStrings: '"',
  //     decimalSeparator: ".",
  //     showLabels: true,
  //     showTitle: true,
  //     title: "Complainants",
  //     useTextFile: false,
  //     useBom: true,
  //     useKeysAsHeaders: true,
  //     // headers: [
  //     //   "Name",
  //     //   "Dept",
  //     //   "Earned leave av.",
  //     //   "Earned leave taken",
  //     //   "Current month taken",
  //     //   "Medical leave av.",
  //     //   "Medical leave taken",
  //     //   "Current month taken",
  //     //   "Unpaid leave",
  //     // ],
  //   };

  //   const csvExporter = new ExportToCsv(options);

  //   csvExporter.generateCsv(res.data);
  //   setLoading(false);
  // };

  return (
    <div className="p-5">
      {showExport && (
        <ModalDialog
          show={showExport}
          title="Export"
          onClose={() => setShowExport(false)}
        >
          <ExportComplaints filter={filter} />
        </ModalDialog>
      )}
      {showAttachments && (
        <ModalDialog
          show={showAttachments !== undefined}
          title="Attachments"
          onClose={() => setShowAttachments(undefined)}
        >
          <AttachmentWidget complaint_id={showAttachments.id} />
        </ModalDialog>
      )}
      {showPopUp && (
        <ModalDialog
          title=""
          show={showPopUp}
          onClose={() => {
            localStorage.setItem("cf", Date.now().toString());
            setShowPopUp(false);
          }}
        >
          <div
            className="flex justify-center p-4"
            style={{ height: "75vh", width: "auto", minWidth: "70vw" }}
          >
            <img
              src="/images/complaint_flow_chart.png"
              alt=""
              style={{ height: "75vh", width: "auto" }}
            />
          </div>
        </ModalDialog>
      )}
      {showHistory !== "" && (
        <DrawerModal
          location={DrawerModalLoaction.right}
          show={showHistory !== ""}
          onClose={() => setShowHistory("")}
          title="History"
        >
          <HistoryWidget complaint_id={showHistory} />
        </DrawerModal>
      )}
      {showComment !== "" && (
        <DrawerModal
          onClose={() => setShowComment("")}
          title="Comments"
          show={showComment !== ""}
          location={DrawerModalLoaction.right}
        >
          <CommentWidget complaint_id={showComment} />
        </DrawerModal>
      )}
      <div className="">
        <div className="grid grid-col-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-6 gap-4 py-2 ">
          <div className="flex items-center gap-2 border p-4 rounded-md  justify-between bg-white">
            <div className="">
              <div className="text-sm text-gray-500">Total Tickets</div>
              <div className="font-semibold text-2xl">{total ?? 0}</div>
              {/* {ticketType.map(({ ticket_type, ticket_count }) => (
                <div key={ticket_type} className="flex  bg-white">
                  <div className="flex flex-row">
                    <div className="text-xs text-gray-500">
                      {ticket_type} -{" "}
                    </div>
                    <div className="font-semibold text-sm">{ticket_count}</div>
                  </div>
                </div>
              ))} */}
              <div className="">
                <FilterDropDown
                  placeholder="Ticket Type"
                  onClearAll={() =>
                    setFilter({
                      ...filter,
                      filterData: {
                        ...filter.filterData,
                        ticket_type: undefined,
                      },
                    })
                  }
                  onDelete={(type) => {
                    setFilter({
                      ...filter,
                      filterData: {
                        ...filter.filterData,
                        ticket_type: filter.filterData?.ticket_type?.filter(
                          (f) => f !== type
                        ),
                      },
                    });
                  }}
                  onSelect={(type) => {
                    setFilter((old) => ({
                      ...old,
                      filterData: {
                        ...old.filterData,
                        ticket_type: [
                          ...(old.filterData?.ticket_type ?? []),
                          type,
                        ],
                      },
                    }));
                  }}
                  // selectedOptions={filter.filterData?.ticket_type ?? [ComplaintTicketType.incident]}
                  selectedOptions={filter.filterData?.ticket_type ?? []}
                  options={Object.values(ComplaintTicketType).map((t) => ({
                    label: t,
                    value: t,
                  }))}
                />
              </div>
            </div>
            <div className="h-8 w-8  rounded-full flex justify-center items-center bg-blue-200">
              {" "}
              <Icon
                path={mdiTextBoxMultiple}
                className="h-4 w-4 text-white"
              />{" "}
            </div>
          </div>
          <div className="flex items-center gap-2 border p-4 rounded-md  justify-between bg-white">
            <div className="">
              <div className="text-sm text-gray-500">Pending complaints</div>
              <div className="font-semibold text-2xl">
                {analytic?.find(
                  (d) => d.status.toLowerCase() === "Pending".toLowerCase()
                )?.count ?? 0}
              </div>
            </div>
            <div className="h-8 w-8  rounded-full flex justify-center items-center bg-yellow-600">
              <Icon path={mdiFileClock} className="h-4 w-4 text-white" />
            </div>
          </div>
          <div className="flex items-center gap-2 border p-4 rounded-md justify-between bg-white">
            <div>
              <div className="text-sm text-gray-500">Due Today</div>
              <div className="font-semibold text-2xl">{overDue.today ?? 0}</div>
            </div>
            <div className="h-8 w-8 rounded-full flex justify-center items-center bg-blue-200">
              <Icon path={mdiTextBoxMultiple} className="h-4 w-4 text-white" />
            </div>
          </div>
          <div className="flex items-center gap-2 border p-2 rounded-md  justify-between bg-white">
            <div className="">
              <div className="text-sm text-gray-500">
                Under progress complaints
              </div>
              <div className="font-semibold text-2xl">
                {analytic?.find(
                  (d) =>
                    d.status.toLowerCase() === "Under progress".toLowerCase()
                )?.count ?? 0}
              </div>
            </div>
            <div className="p-2 rounded-full flex justify-center items-center bg-yellow-400">
              <Icon path={mdiTools} className="h-4 w-4 text-white" />
            </div>
          </div>
          <div className="flex items-center gap-2 border p-2 rounded-md  justify-between bg-white">
            <div className="">
              <div className="text-sm text-gray-500">
                Noted for future correction
              </div>
              <div className="font-semibold text-2xl">
                {analytic?.find(
                  (d) =>
                    d.status.toLowerCase() === "Noted for future".toLowerCase()
                )?.count ?? 0}
              </div>
            </div>
            <div className="p-2 rounded-full flex justify-center items-center bg-green-200">
              <Icon path={mdiBookCheck} className="h-4 w-4 text-white" />{" "}
            </div>
          </div>
          <div className="flex items-center gap-2 border p-2 rounded-md  justify-between bg-white">
            <div className="">
              <div className="text-sm text-gray-500">Solved complaints</div>
              <div className="font-semibold text-2xl">
                {analytic?.find(
                  (d) => d.status.toLowerCase() === "Solved".toLowerCase()
                )?.count ?? 0}
              </div>
            </div>
            <div className="p-2 rounded-full flex justify-center items-center bg-teal">
              <Icon
                path={mdiTextBoxCheckOutline}
                className="h-4 w-4 text-white"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white p-3 rounded-md">
        <div className="flex justify-between">
          <div className=" flex gap-8">
            {averageTime && (
              <div className="mt-2">
                <h3 className="text-sm text-gray-500 border-b border-gray-300">
                  Average turnaround time
                </h3>
                <div className="flex text-sm gap-5 mt-2">
                  <p className="text-gray-500">
                    Current month :{" "}
                    <b className="text-black">
                      {/* {(averageTime?.cur_month[0].count ?? 0) > 0
                        ? Math.ceil(
                            (averageTime?.cur_month[0].days ?? 0) /
                              (averageTime?.cur_month[0].count ?? 1)
                          )
                        : 0}{" "}
                      days */}
                      {(averageTime?.cur_month[0]?.count ?? 0) > 0
                        ? Math.ceil(
                            (averageTime?.cur_month[0]?.days ?? 0) /
                              (averageTime?.cur_month[0]?.count ?? 1) // Use 1 to avoid division by zero
                          )
                        : 0}{" "}
                      days
                    </b>
                  </p>
                  <p className="text-gray-500">
                    Last month :{" "}
                    <b className="text-black">
                      {/* {(averageTime?.last_month.count ?? 0) > 0
                        ? Math.ceil(
                            (averageTime?.last_month.days ?? 0) /
                              (averageTime?.last_month.count ?? 1)
                          )
                        : 0}{" "}
                      days */}
                      {(averageTime?.last_month[0]?.count ?? 0) > 0
                        ? Math.ceil(
                            (averageTime?.last_month[0]?.days ?? 0) /
                              (averageTime?.last_month[0]?.count ?? 1) // Use 1 to avoid division by zero
                          )
                        : 0}{" "}
                      days
                    </b>
                  </p>
                  <p className="text-gray-500">
                    All :{" "}
                    <b className="text-black">
                      {(averageTime?.all_days[0]?.count ?? 0) > 0
                        ? Math.ceil(
                            (averageTime?.all_days[0]?.days ?? 0) /
                              (averageTime?.all_days[0]?.count ?? 0)
                          )
                        : 0}{" "}
                      days
                    </b>
                  </p>
                </div>
              </div>
            )}

            <div className="mt-2">
              <h3 className="text-sm text-gray-500 border-b border-gray-300">
                Overdue
              </h3>
              <div className="flex text-sm gap-5 mt-2">
                <p className="text-gray-500">
                  All: <b className="text-black">{overDue.all}</b>
                </p>
                <p className="text-gray-500">
                  Today : <b className="text-black">{overDue.today}</b>
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 items-end">
            <div className="flex flex-row col-span-1 gap-4">
              <div className="flex items-center  gap-1">
                <label htmlFor="fromDate" className="">
                  From
                </label>
                <input
                  type="date"
                  id="fromDate"
                  className="p-1 rounded border-2 focus:outline-none"
                  value={
                    filter.filterData?.fromDate
                      ? moment(filter.filterData.fromDate).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const selectedDate = UTCToLocalDate(e.target.value);
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      filterData: {
                        ...prevFilter.filterData,
                        fromDate: selectedDate,
                      },
                    }));
                  }}
                />
              </div>
              <div className="flex items-center  gap-1">
                <label htmlFor="toDate" className="">
                  To
                </label>
                <input
                  type="date"
                  id="toDate"
                  className="p-1 rounded border-2 focus:outline-none "
                  value={
                    filter.filterData?.toDate
                      ? moment(filter.filterData.toDate).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const selectedDate = UTCToLocalDate(e.target.value);
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      filterData: {
                        ...prevFilter.filterData,
                        toDate: selectedDate,
                      },
                    }));
                  }}
                />
              </div>
              {(filter.filterData?.fromDate || filter.filterData?.toDate) && (
                <button
                  title="Clear dates"
                  onClick={() =>
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      filterData: {
                        ...prevFilter.filterData,
                        fromDate: undefined,
                        toDate: undefined,
                      },
                    }))
                  }
                  className="flex gap-1 items-center cursor-pointer rounded p-1 border hover:scale-105 duration-200 transform"
                >
                  <Icon path={mdiClose} size={0.7} />
                </button>
              )}
            </div>
            <Tooltip title="Complainant name, mobile,complaint ID, Responsible person">
              <div className="">
                <SearchFieldWidget
                  placeholder="Search..."
                  onClear={() => {
                    setFilter((old) => ({ ...old, search: "", page: 1 }));
                  }}
                  onSubmit={(q) => {
                    setFilter((old) => ({ ...old, search: q, page: 1 }));
                  }}
                />
              </div>
            </Tooltip>
            <button
              className="border rounded px-2 py-1 flex items-center gap-2"
              onClick={() => setShowExport(true)}
            >
              <Icon path={mdiMicrosoftExcel} className="h-4 w-4  " />
              Export
            </button>
          </div>
        </div>
        <div>
          {averageTime && (
            <div className="mt-2">
              <h3 className="text-sm text-gray-500 border-b border-gray-300">
                Average TAT Acc to Ticket Type
              </h3>
              <div className="flex text-sm gap-5 mt-2">
                <p className="text-gray-500">
                  Current month :{" "}
                  <b className="text-black">
                    {(averageTime?.ticket_type_cur_month[0]?.count ?? 0) > 0
                      ? Math.ceil(
                          (averageTime?.ticket_type_cur_month[0]?.days ?? 0) /
                            (averageTime?.ticket_type_cur_month[0]?.count ?? 1) // Use 1 to avoid division by zero
                        )
                      : 0}{" "}
                    days
                  </b>
                </p>
                <p className="text-gray-500">
                  Last month :{" "}
                  <b className="text-black">
                    {(averageTime?.ticket_type_last_month[0]?.count ?? 0) > 0
                      ? Math.ceil(
                          (averageTime?.ticket_type_last_month[0]?.days ?? 0) /
                            (averageTime?.ticket_type_last_month[0]?.count ?? 1) // Use 1 to avoid division by zero
                        )
                      : 0}{" "}
                    days
                  </b>
                </p>
                <p className="text-gray-500">
                  All :{" "}
                  <b className="text-black">
                    {(averageTime?.ticket_type_all_days[0]?.count ?? 0) > 0
                      ? Math.ceil(
                          (averageTime?.ticket_type_all_days[0]?.days ?? 0) /
                            (averageTime?.ticket_type_all_days[0]?.count ?? 0)
                        )
                      : 0}{" "}
                    days
                  </b>
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end items-end text-sm mt-2">
          <button
            onClick={() => {
              setFilter(defaultFilter);
              setSelectedOrg("");
            }}
          >
            Clear
          </button>
        </div>
        <div className="rounded  p-1 grid grid-cols-1 md:grid-cols-9  gap-3   bg-white">
          <div className="">
            <FilterDropDown
              placeholder="Source"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    source: undefined,
                  },
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    source: filter.filterData?.source?.filter(
                      (f) => f !== type
                    ),
                  },
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  filterData: {
                    ...old.filterData,
                    source: [...(old.filterData?.source ?? []), type],
                  },
                }));
              }}
              selectedOptions={filter.filterData?.source ?? []}
              options={Object.values(SourceType).map((t) => ({
                label: t,
                value: t,
              }))}
            />
          </div>
          {/* <div className="">
            <FilterDropDown
              placeholder="Complaint related to"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    complainRelatedTo: undefined,
                  },
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    complainRelatedTo:
                      filter.filterData?.complainRelatedTo?.filter(
                        (f) => f !== type
                      ),
                  },
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  filterData: {
                    ...old.filterData,
                    complainRelatedTo: [
                      ...(old.filterData?.complainRelatedTo ?? []),
                      type,
                    ],
                  },
                }));
              }}
              selectedOptions={filter.filterData?.complainRelatedTo ?? []}
              options={Object.values(complaint_related_to).map((t) => ({
                label: t,
                value: t,
              }))}
            />
          </div>{" "} */}
          {/* <div className="  ">
            <FilterDropDown
              placeholder=" Pre / Post Delivery"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    pre_post: undefined,
                  },
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    pre_post: filter.filterData?.pre_post?.filter(
                      (f) => f !== type
                    ),
                  },
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  filterData: {
                    ...old.filterData,
                    pre_post: [...(old.filterData?.pre_post ?? []), type],
                  },
                }));
              }}
              selectedOptions={filter.filterData?.pre_post ?? []}
              options={["Pre Delivery", "Post Delivery"].map((t) => ({
                label: t,
                value: t,
              }))}
            />
          </div> */}
          <div className="  ">
            <FilterDropDown
              placeholder="Responsible dept"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    res_dep: undefined,
                  },
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    res_dep: filter.filterData?.res_dep?.filter(
                      (f) => f !== type
                    ),
                  },
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  filterData: {
                    ...old.filterData,
                    res_dep: [...(old.filterData?.res_dep ?? []), type],
                  },
                }));
              }}
              selectedOptions={filter.filterData?.res_dep ?? []}
              options={Object.entries(SubDepartments).flatMap(
                ([department, subDepartments]) =>
                  subDepartments.map((subDept) => ({
                    label: `${department}-${subDept}`,
                    value: `${department}-${subDept}`,
                  }))
              )}
            />
          </div>
          <div className="  ">
            <FilterDropDown
              placeholder="created by dept"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    createdByDept: undefined,
                  },
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    createdByDept: filter.filterData?.createdByDept?.filter(
                      (f) => f !== type
                    ),
                  },
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  filterData: {
                    ...old.filterData,
                    createdByDept: [
                      ...(old.filterData?.createdByDept ?? []),
                      type,
                    ],
                  },
                }));
              }}
              selectedOptions={filter.filterData?.createdByDept ?? []}
              options={Object.entries(Departments).map(([department]) => ({
                label: department,
                value: department,
              }))}
            />
          </div>
          <div className="  ">
            <FilterDropDown
              placeholder="Priority"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    priority: undefined,
                  },
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    priority: filter.filterData?.priority?.filter(
                      (f) => f !== type
                    ),
                  },
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  filterData: {
                    ...old.filterData,
                    priority: [...(old.filterData?.priority ?? []), type],
                  },
                }));
              }}
              selectedOptions={filter.filterData?.priority ?? []}
              options={Object.values(PriorityType).map((t) => ({
                label: t,
                value: t,
              }))}
            />
          </div>
          <div className=" ">
            <FilterDropDown
              placeholder="Status"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    status: undefined,
                  },
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    status: filter.filterData?.status?.filter(
                      (f) => f !== type
                    ),
                  },
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  filterData: {
                    ...old.filterData,
                    status: [...(old.filterData?.status ?? []), type],
                  },
                }));
              }}
              selectedOptions={filter.filterData?.status ?? []}
              options={Object.values(complaintStatus).map((t) => ({
                label: t,
                value: t,
              }))}
            />
          </div>
          <div className="    ">
            <div className="flex gap-1 items-center">
              <select
                className="border rounded text-sm w-full h-8 focus:outline-none cursor-pointer"
                name="active"
                id="active"
                value={
                  filter.filterData?.active !== undefined
                    ? filter.filterData!.active
                      ? "true"
                      : "false"
                    : ""
                }
                onChange={(e) => {
                  if (e.target.value === "") {
                    delete filter.filterData?.active;
                    setFilter((o) => ({
                      ...o,
                    }));
                    return;
                  }
                  setFilter((o) => ({
                    ...o,
                    filterData: {
                      ...o.filterData,
                      active: e.target.value === "true",
                    },
                  }));
                }}
              >
                <option value="">All</option>
                <option value="true">Active</option>
                <option value="false">Closed</option>
              </select>
            </div>
          </div>{" "}
          {/* <div className=" ">
            <FilterDropDown
              placeholder="State"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    state: undefined,
                  },
                })
              }
              onDelete={(type) => {
                setFilter({
                  ...filter,
                  filterData: {
                    ...filter.filterData,
                    state: filter.filterData?.state?.filter((f) => f !== type),
                  },
                });
              }}
              onSelect={(type) => {
                setFilter((old) => ({
                  ...old,
                  filterData: {
                    ...old.filterData,
                    state: [...(old.filterData?.state ?? []), type],
                  },
                }));
              }}
              selectedOptions={filter.filterData?.state ?? []}
              options={stateData.map((state) => ({
                label: state.name,
                value: state.name,
              }))}
            />
          </div> */}
          <div className=" z-20 ">
            <div className="flex gap-1 items-center w-full">
              {!loading && (
                <div>
                  <EmployeeMultiSearchWidget
                    placeholder="Created By"
                    selectedEmployees={selectedEmployees}
                    onSelect={handleSelect}
                    onDelete={handleDelete}
                  />
                </div>
              )}
              {filter.filterData && filter.filterData.emp_names && (
                <button
                  className="text-xs"
                  onClick={() => {
                    setLoading(true);
                    setFilter((old) => ({
                      ...old,
                      filterData: {
                        ...old.filterData,
                        created_by_name: undefined,
                        emp_names: undefined,
                      },
                    }));
                    setLoading(false);
                  }}
                >
                  <button
                    onClick={handleClearAll}
                    className="focus:outline-none"
                  >
                    <Icon path={mdiClose} className="h-4 w-4 bg-red-500" />
                  </button>
                </button>
              )}
            </div>
          </div>{" "}
          <div className=" z-20 ">
            <div className="flex gap-1 items-center w-full">
              {!loading && (
                <EmployeeSearchWidget
                  placeholder="Responsible person"
                  emp={
                    filter.filterData?.responsiblePerson
                      ? {
                          uid: filter.filterData
                            ? filter.filterData?.responsiblePerson[0]
                            : "",
                          name: filter.filterData.emp_name ?? "",
                        }
                      : undefined
                  }
                  withBtn={false}
                  onSelect={(emp) => {
                    setFilter((old) => ({
                      ...old,
                      filterData: {
                        ...old.filterData,
                        responsiblePerson: [emp.uid!],
                        emp_name: emp.first_name + " " + emp.last_name,
                      },
                    }));
                  }}
                />
              )}
              {filter.filterData && filter.filterData.emp_name && (
                <button
                  className="text-xs"
                  onClick={() => {
                    setLoading(true);
                    setFilter((old) => ({
                      ...old,
                      filterData: {
                        ...old.filterData,
                        responsiblePerson: undefined,
                        emp_name: undefined,
                      },
                    }));
                    setLoading(false);
                  }}
                >
                  <Icon path={mdiClose} className="h-4 w-4 text-red-500"></Icon>
                  Clear
                </button>
              )}
            </div>
          </div>{" "}
          <div className=" z-20 ">
            <div className="flex gap-1 items-center w-full">
              {!loading && (
                <div>
                  <OrgSearchField
                    disabled={selectedOrg.length > 0}
                    value={selectedOrg || ""}
                    onSelect={(org) => {
                      setSelectedOrg(org.name || ""); // Set the selected organization
                      setFilter((old) => ({
                        ...old,
                        filterData: {
                          ...old.filterData,
                          org: org.name,
                        },
                      }));
                    }}
                  />
                </div>
              )}
              {filter.filterData && filter.filterData.org && userHasOrg && (
                <button
                  className="text-xs"
                  onClick={() => {
                    setLoading(true);
                    setFilter((old) => ({
                      ...old,
                      filterData: {
                        ...old.filterData,
                        org: undefined, // Clear the organization from the filter
                      },
                    }));
                    setSelectedOrg(""); // Clear the selected organization
                    setLoading(false);
                  }}
                >
                  <Icon path={mdiClose} className="h-4 w-4 text-red-500" />
                  Clear
                </button>
              )}
            </div>
          </div>{" "}
        </div>
      </div>
      <div className="mt-3 p-2 bg-white">
        <Pagination
          itemCount={complaints.length}
          page={filter.page}
          total={total}
          count={filter.count}
          onChange={(page) => {
            setFilter((old) => ({ ...old, page }));
          }}
        />
      </div>
      <div className=" flex justify-center" style={{ height: "90vh" }}>
        <div
          id="scroll_listener"
          className="overflow-auto h-full border rounded-lg bg-white w-full"
        >
          <table className="divide-y divide-gray-200 relative text-xs w-full">
            <thead className="bg-gray-200 py-2 sticky -top-1 z-10 whitespace-nowrap">
              <tr className="py-2 bg-gray-200 text-gray-600">
                <th className="p-2 lg:sticky lg:left-0  bg-gray-200 ">
                  <div className="flex justify-center">
                    <button
                      onClick={() => setIsOpen(!isOpen)}
                      className="mb-2 p-2  rounded"
                    >
                      <span>
                        <Icon path={mdiViewColumn} className="h-4 w-4" />
                      </span>
                    </button>
                  </div>
                </th>

                {columnVisibility.date && (
                  <th
                    onClick={handleSortDate}
                    className="p-2 border lg:sticky lg:left-80p  bg-gray-200 cursor-pointer"
                  >
                    <Tooltip
                      title={
                        filter.filterData?.sort_order === "desc"
                          ? "Descending"
                          : "Ascending"
                      }
                    >
                      <div className="flex gap-2 justify-center">
                        Date{" "}
                        <span>
                          <Icon path={mdiSwapVertical} className="h-4 w-4" />
                        </span>
                      </div>
                    </Tooltip>
                  </th>
                )}
                {columnVisibility.dueDate && (
                  <th className="border">Due Date</th>
                )}
                {columnVisibility.ago && <th className="border">Ago</th>}
                {columnVisibility.complaintId && (
                  <th className="border">Complaint ID</th>
                )}
                {columnVisibility.deviceId && (
                  <th className="border">Device ID</th>
                )}
                {columnVisibility.org && (
                  <th className="border">Organization</th>
                )}
                {columnVisibility.responsiblePerson && (
                  <th className="border">Responsible Person</th>
                )}
                {columnVisibility.priority && (
                  <th className="border">Priority</th>
                )}
                {columnVisibility.status && (
                  <th className=" border  lg:sticky  lg:left-170p bg-gray-200">
                    Status
                  </th>
                )}
                {columnVisibility.visibility && (
                  <th className="border">Visibility</th>
                )}
                {columnVisibility.complaintBy && (
                  <th className="border">Complaint By</th>
                )}
                {columnVisibility.createdBy && (
                  <th className=" border  lg:sticky  lg:left-170p bg-gray-200">
                    Created By
                  </th>
                )}
                {columnVisibility.createdByDept && (
                  <th className=" border  lg:sticky  lg:left-170p bg-gray-200">
                    Created By Deptartment
                  </th>
                )}
                {columnVisibility.complaintRelatedTo && (
                  <th className=" border  lg:sticky  lg:left-170p bg-gray-200">
                    Complaint Related To
                  </th>
                )}
                {columnVisibility.source && <th className="border">Source</th>}
                {columnVisibility.prePostDelivery && (
                  <th className="border">Pre/Post Delivery</th>
                )}
                {columnVisibility.responsibleDepartment && (
                  <th className="border">Responsible Department</th>
                )}
                {columnVisibility.complainantName && (
                  <th className="border">Complainant Name</th>
                )}
                {columnVisibility.complainantMobile && (
                  <th className="border">Complainant Mobile</th>
                )}
                {columnVisibility.complainantAddress && (
                  <th className=" border    whitespace-nowrap w-10 overflow-hidden">
                    Complainant Address
                  </th>
                )}
                {columnVisibility.nextActionDate && (
                  <th className="border">Next Action Date</th>
                )}
                {columnVisibility.subject && (
                  <th className="border">Subject</th>
                )}
                {columnVisibility.description && (
                  <th className="border">Description</th>
                )}
              </tr>
            </thead>
            <tbody>
              {complaints.map((complaint, i) => (
                <tr
                  key={i}
                  className={`border py-2 text-xs hover:bg-gray-200 sticky-row  ${
                    !complaint.active ? "text-gray-400" : ""
                  }`}
                >
                  <td className="lg:sticky lg:left-0 sticky-field">
                    <div className="flex gap-1 px-1">
                      <Tooltip title="Complaint ID">
                        <svg
                          onClick={() => {
                            navigator.clipboard.writeText(complaint.id);

                            showToast({
                              type: ToastType.error,
                              text: "Copied complaint ID to clip board!",
                            });
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 cursor-pointer hover:text-red-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                          />
                        </svg>
                      </Tooltip>
                      <Tooltip title="Notes">
                        <svg
                          onClick={() => setShowComment(complaint.id)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 hover:text-red-600 cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                          />
                        </svg>
                      </Tooltip>
                      <Tooltip title="history">
                        <svg
                          onClick={() => setShowHistory(complaint.id)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4  cursor-pointer hover:text-red-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </Tooltip>
                      <Tooltip title="Attachment">
                        <div
                          onClick={() => setShowAttachments(complaint)}
                          className="cursor-pointer"
                        >
                          <Icon path={mdiAttachment} className="w-4 h-4"></Icon>
                        </div>
                      </Tooltip>
                    </div>
                  </td>
                  {columnVisibility.date && (
                    <td
                      className={`px-3 py-1 border whitespace-nowrap lg:sticky lg:left-80p sticky-field `}
                    >
                      {moment(complaint.complaint_date)
                        .utcOffset(330)
                        .format("DD MMM YYYY")}
                    </td>
                  )}
                  {columnVisibility.dueDate && (
                    <td
                      className={`px-3 py-1 border whitespace-nowrap lg:sticky lg:left-80p sticky-field `}
                    >
                      {moment(complaint.next_action_date)
                        .utcOffset(330)
                        .format("DD MMM YYYY")}
                    </td>
                  )}
                  {columnVisibility.ago && (
                    <td>
                      <p className="text-center">
                        {moment().diff(moment(complaint.complaint_date), "day")}
                      </p>
                    </td>
                  )}
                  {columnVisibility.complaintId && (
                    <td className="px-2 py-1 border">
                      <Tooltip title={complaint.description}>
                        <NavLink
                          // target="_blank"
                          to={urls.complaint_detail + "?id=" + complaint.id}
                        >
                          <div className="mx-1 cursor-pointer flex  text-blue-400 ">
                            {complaint.id}
                          </div>
                        </NavLink>
                      </Tooltip>
                    </td>
                  )}
                  {columnVisibility.deviceId && (
                    <td className="px-2 py-1 border">
                      {complaint.device_id ?? "--"}
                    </td>
                  )}
                  {columnVisibility.org && (
                    <td className="px-2 py-1 border">
                      {complaint.org ?? "--"}
                    </td>
                  )}
                  {columnVisibility.responsiblePerson && (
                    <td className="px-2 py-1 border">
                      {complaint.res_person_name ?? "--"}
                    </td>
                  )}
                  {columnVisibility.priority && (
                    <td
                      align="center"
                      className={`px-2  border  font-medium whitespace-nowrap ${
                        complaint.priority === PriorityType.high
                          ? "text-yellow-700"
                          : complaint.priority === PriorityType.medium
                          ? "text-yellow-500"
                          : "text-yellow-200"
                      }`}
                    >
                      {complaint.priority}
                    </td>
                  )}
                  {columnVisibility.status && (
                    <td
                      align="center"
                      className={`px-3  border lg:sticky lg:left-170p sticky-field font-medium whitespace-nowrap ${
                        complaint.status.toLowerCase() === "pending"
                          ? "text-yellow-700"
                          : complaint.status.toLowerCase() === "solved"
                          ? "text-green-200"
                          : "text-yellow-500"
                      }`}
                    >
                      {complaint.status}
                    </td>
                  )}
                  {columnVisibility.visibility && (
                    <td
                      align="center"
                      className={`px-2  border  font-medium whitespace-nowrap  `}
                    >
                      {complaint.active ? "Active" : "Closed"}
                    </td>
                  )}
                  {columnVisibility.complaintBy && (
                    <td className="px-2 py-1 border  ">
                      <NavLink
                        // target="_blank"
                        to={urls.complaint_detail + "?id=" + complaint.id}
                      >
                        <div className="mx-1 cursor-pointer flex  text-blue-400    whitespace-nowrap w-24 overflow-hidden overflow-ellipsis ">
                          {complaint.complaint_by}
                        </div>
                      </NavLink>
                    </td>
                  )}
                  {columnVisibility.createdBy && (
                    <td className="px-3 py-1 border whitespace-nowrap lg:sticky lg:left-80p sticky-field">
                      <NavLink
                        // target="_blank"
                        to={urls.complaint_detail + "?id=" + complaint.id}
                      >
                        <Tooltip title={complaint.created_by_name ?? ""}>
                          <div className="mx-1 cursor-pointer flex  text-blue-400  w-24 overflow-hidden overflow-ellipsis ">
                            {complaint.created_by_name}
                          </div>
                        </Tooltip>
                      </NavLink>
                    </td>
                  )}
                  {columnVisibility.createdByDept && (
                    <td
                      className={`px-3 py-1 border whitespace-nowrap lg:sticky lg:left-80p sticky-field `}
                    >
                      {complaint.createdByDept}
                    </td>
                  )}
                  {columnVisibility.complaintRelatedTo && (
                    <td
                      className={`px-3 py-1 border whitespace-nowrap lg:sticky lg:left-80p sticky-field `}
                    >
                      {complaint.related_to}
                    </td>
                  )}
                  {columnVisibility.source && (
                    <td
                      className={`px-3 py-1 border whitespace-nowrap lg:sticky lg:left-80p sticky-field `}
                    >
                      {complaint.source}
                    </td>
                  )}
                  {columnVisibility.prePostDelivery && (
                    <td className="px-2 py-1 border">
                      {complaint.pre_post_delivery ?? "--"}
                    </td>
                  )}
                  {columnVisibility.responsibleDepartment && (
                    <td className="px-2 py-1 border">
                      {complaint.res_dep ?? "--"}
                    </td>
                  )}
                  {columnVisibility.complainantName && (
                    <td className="px-2 py-1 border">
                      <Tooltip title={complaint.complainant_name}>
                        <p className="whitespace-nowrap w-40 overflow-hidden overflow-ellipsis">
                          {complaint.complainant_name ?? "--"}
                        </p>
                      </Tooltip>
                    </td>
                  )}
                  {columnVisibility.complainantMobile && (
                    <td className="px-2 py-1 border">
                      {complaint.complainant_mobile ?? "--"}
                    </td>
                  )}
                  {columnVisibility.complainantAddress && (
                    <td className="px-2 py-1 border ">
                      <Tooltip title={complaint.address}>
                        <p className="whitespace-nowrap w-40 overflow-hidden overflow-ellipsis">
                          {complaint.city ?? complaint.address}
                        </p>
                      </Tooltip>
                    </td>
                  )}
                  {columnVisibility.nextActionDate && (
                    <td
                      className={`px-3 py-1 border whitespace-nowrap lg:sticky lg:left-80p sticky-field `}
                    >
                      {complaint.next_action_date
                        ? moment(complaint.next_action_date).format(
                            "DD MMM YYYY"
                          )
                        : "--"}
                    </td>
                  )}
                  {columnVisibility.subject && (
                    <td className="px-2 py-1 border whitespace-nowrap max-w-sm overflow-hidden">
                      {complaint.subject}
                    </td>
                  )}
                  {columnVisibility.description && (
                    <td className="px-2 py-1 border whitespace-nowrap max-w-md overflow-hidden">
                      <Tooltip title={complaint.description}>
                        <p>{complaint.description}</p>
                      </Tooltip>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Column Chooser on the Right */}
        <div className="column-chooser flex flex-col justify-center ml-4">
          {isOpen && (
            <div className="flex flex-col">
              {Object.keys(initialColumnVisibility).map((key) => (
                <label key={key} className="flex items-center mb-1">
                  <input
                    type="checkbox"
                    checked={columnVisibility[key as ColumnKeys]}
                    onChange={() => toggleColumnVisibility(key as ColumnKeys)}
                  />
                  <span className="ml-2">
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                  </span>
                </label>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="pt-2 bg-white">
        <Pagination
          itemCount={complaints.length}
          page={filter.page}
          total={total}
          count={filter.count}
          onChange={(page) => {
            setFilter((old) => ({ ...old, page }));
          }}
        />
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default ComplaintListingPage;
