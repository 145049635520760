import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  getHeaders,
  toQueryString,
} from "../../api_client";
import {
  EmpAsset,
  LeaveAvailableModel,
  LeaveCounter,
  LeaveTakenModel,
} from "../../models/user_model";

export const CreateLeaveRequestRepo = async (data: {
  leave_name: string;
  from_date: string;
  to_date: string;
  period?: string;
  message: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/leave-request`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const CreateApprovedLeaveRequest = async (data: {
  uid: any;
  leave_name: string;
  from_date: string;
  to_date: string;
  period?: string;
  message: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/leave-request/approved`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetLeaveRequests = async (data: {
  uid?: string;
  reporting_uid?: string;
  page: number;
  count: number;
}) => {
  try {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/hrm/leave-request${toQueryString(data)}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetLeaveRequestsApproved = async () => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/leave-request/approved`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const UpdateLeaveRequests = async (data: {
  id: number;
  status: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/leave-request`;
    const res = await fetchPut(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const CancelLeaveRequest = async (id: number) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/leave-request/cancel`;
    const res = await fetchPut(url, { id });

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetLeavesData = async () => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/leave-data/self`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetHolidayOfMonth = async ({
  year,
  month,

  emp_id,
}: {
  month?: number;
  year?: number;

  emp_id?: string;
}) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/month-holiday-calendar?`;
    if (year) {
      url = url + `year=${year}&`;
    }
    if (month) {
      url = url + `month=${month}&`;
    }
    if (emp_id) {
      url = url + `emp_id=${emp_id}&`;
    }

    const res = await fetchGet(url);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetOverviewData = async () => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/overview-data`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetPublicHolidayList = async ({
  month,
  year,
}: {
  month: number;
  year: number;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/month-holiday-calendar?month=${month}&year=${year}`;

    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetHolidayAll = async (year: number, zone?: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/public-holiday?year=${year}&zone=${zone}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetLeaveDataAll = async (data: {
  month: number;
  year: number;
}) => {
  try {
    const url = `${
      process.env.REACT_APP_BACKEND_BASE_URL
    }/api/v3/hrm/leave-data${toQueryString(data)}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const UpdateLeaveData = async (data: {
  uid: string;
  name: string;
  leave_taken: LeaveTakenModel;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/leave-data`;
    const res = await fetchPut(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const PostProfileImage = async (uid: any, data: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${uid}/upload-pic`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetBirthdayToday = async (day?: any, month?: any, year?: any) => {
  try {
    if (day && month) {
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/birthday?day=${day}&month=${month}&year=${year}`;
      const res = await fetchGet(url);
      return res;
    } else {
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/birthday`;
      const res = await fetchGet(url);
      return res;
    }
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetAnniversary = async (day?: any, month?: any, year?: any) => {
  try {
    if (day && month) {
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/aniversary?day=${day}&month=${month}&year=${year}`;
      const res = await fetchGet(url);
      return res;
    } else {
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/aniversary`;
      const res = await fetchGet(url);
      return res;
    }
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetDocs = async (uid: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${uid}/doc`;
    // const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${uid}/my-doc`;

    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const PostDocs = async (uid: string, data: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${uid}/doc`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const UpdateDoc = async (uid: string, data: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${uid}/doc`;
    const res = await fetchPut(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const DeleteDoc = async (uid: string, doc_id: number) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${uid}/doc/${doc_id}`;
    const res = await fetchDelete(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// Asset manipulations
export const GetAssets = async (uid: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${uid}/assets`;

    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const PostAssets = async ({
  profileUID,
  asset,
  assigned_by,
  assigned_by_name,
}: {
  profileUID: string;
  asset: string;
  assigned_by: string;
  assigned_by_name: string;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${profileUID}/assets`;
    // const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${uid}/my-doc`;
    console.log(profileUID);
    const body: EmpAsset = {
      asset: asset,
      emp_id: profileUID,
      assigned_on: new Date().toISOString(),
      assigned_by: assigned_by,
      assigned_by_name: assigned_by_name,
      status: "ASSIGNED",
    };
    console.log(body);
    const res = await fetchPost(url, body);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const PatchAssets = async ({
  asset_id,
  profileUID,
  status,
}: {
  asset_id: string;
  profileUID: string;
  status: string;
}) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${profileUID}/assets/${asset_id}`;
  const body = {
    status: status,
  };
  try {
    const res = await fetchPut(url, body);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const SearchAssets = async (search: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/search-assets?search=${search}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// My docs manipulations

export const GetMyDocs = async (uid: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${uid}/my-doc`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const PostMyDocs = async (uid: string, data: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${uid}/my-doc`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const UpdateMyDoc = async (uid: string, data: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${uid}/my-doc`;
    const res = await fetchPut(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const DeleteMyDoc = async (uid: string, doc_id: number) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/employees/${uid}/my-doc/${doc_id}`;
    const res = await fetchDelete(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// get memories
export const GetMemory = async (params: { page: number; count: number }) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm-post?page=${params.page}&count=${params.count}`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// Post memory
export const PostMemory = async (data: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm-post`;
    const res = await fetchPost(url, data);
    console.log(res);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// api/v3/hrm-post/:post_id/like

// Post memory
export const PostMemoryLike = async (id: string, data: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm-post/${id}/like`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const PostMemoryUnlike = async (id: string, data: any) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm-post/${id}/unlike`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetMemoryComment = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm-post/${id}/comments`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const PostMemoryComment = async (
  id: string,
  data: { comment: string }
) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm-post/${id}/comments`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const DeleteMemoryComment = async (id: string, comment_id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm-post/${id}/comments/${comment_id}`;
    const res = await fetchDelete(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const DeleteMemory = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm-post/${id}`;
    const res = await fetchDelete(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// get

export const GetPollCurrent = async () => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/poll/current-month`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const PostPollCurrent = async (data: {
  poll_id: string;
  response: any;
}) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/poll/current-month`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetPollResponse = async (poll_id: string) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/poll/${poll_id}/responses`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const GetMood = async () => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/org-mood`;
    const res = await fetchGet(url);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const PostMood = async (data: { response: any }) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/hrm/org-mood`;
    const res = await fetchPost(url, data);

    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

// get
//  org-mood

// post
//  org-mood
// response
