import { FunctionComponent, useEffect, useState } from "react";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import HrmButton from "../../components/HrmButton";
import HrmPopup from "../../components/HrmPopup";
import HrmLoader from "../../components/loading";
import AssetSearchWidget from "../../../../new_components/asset_search_widget";
import {
  GetAssets,
  PatchAssets,
  PostAssets,
} from "../../../../../service/repos/hrm/hrm_repo";
import { EmpAsset } from "../../../../../service/models/user_model";
import { X } from "react-feather";
import moment from "moment";
import { GetRolesOfEmpApi } from "../../../../../service/repos/roles_repo";
import { ACTION, MODULE } from "../../../../../utils/enums";
import {
  Permission,
  Role,
} from "../../../../../context_providers/auth/user_model";

interface AssetProps {
  profileUID: any;
}

function Assets({ profileUID }: AssetProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { user, isAuthorised } = useAuth();
  const [assets, setAssets] = useState<EmpAsset[]>([]);
  const { showToast } = useToast();
  // if UID is not same as signed in user UID then show the add asset button
  const [addButton, setAddButton] = useState<boolean>(false);
  const [showRemoveButton, setRemoveButton] = useState<boolean>(false);
  const [readPermissionGiven, setReadPermissionGiven] =
    useState<boolean>(false);
  // Patch asset data
  const patchAsset = async ({
    asset_id,
    profileUID,
    status,
  }: {
    asset_id: string;
    profileUID: string;
    status: string;
  }) => {
    const res = await PatchAssets({
      asset_id: asset_id,
      profileUID: profileUID,
      status: status,
    });
    if (res.success) {
      showToast({
        type: ToastType.success,
        text: "Asset updated successfully",
      });
      getAssets(profileUID);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  // Post asset data
  const postAsset = async (profileUID: string, asset: string) => {
    const res = await PostAssets({
      profileUID: profileUID,
      asset: asset,
      assigned_by: user?.uid,
      assigned_by_name: `${user?.first_name} ${user?.last_name}`,
    });
    if (res.success) {
      showToast({ type: ToastType.success, text: "Asset added successfully" });
      showModal && setShowModal(false);
      getAssets(profileUID);
    } else {
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
  };

  // get assets
  const getAssets = async (profileUID: string) => {
    const res = await GetAssets(profileUID);
    const temp_list: EmpAsset[] = [];
    if (res.success) {
      res.data.map((instance: any, i: any) => {
        const instance_object: EmpAsset = {
          id: instance._id,
          emp_id: instance.emp_id,
          asset: instance.asset,
          assigned_on: instance.assigned_on,
          assigned_by: instance.assigned_by,
          assigned_by_name: instance.assigned_by_name,
          status: instance.status,
        };
        temp_list.push(instance_object);
      });
      setAssets(temp_list);
    }
  };

  const checkCurrentUserPermission = async (uid: string) => {
    const read_permission_result = isAuthorised({
      module: MODULE.emp,
      action: [ACTION.READ],
    });

    const create_permission_result = isAuthorised({
      module: MODULE.emp,
      action: [ACTION.CREATE],
    });

    const delete_permission_result = isAuthorised({
      module: MODULE.emp,
      action: [ACTION.UPDATE],
    });

    const all_permission_result = isAuthorised({
      module: MODULE.emp,
      action: [ACTION.ALL],
    });

    if (read_permission_result) {
      console.log("Read permission found");
      setReadPermissionGiven(true);
      getAssets(profileUID);
    }

    if (create_permission_result) {
      console.log("Add Button will be shown");
      setAddButton(true);
    }

    if (delete_permission_result) {
      console.log("Remove button will be shown");
      setRemoveButton(true);
    }

    if (all_permission_result) {
      console.log("All buttons will be shown");
      setAddButton(true);
      setRemoveButton(true);
    }

    if (uid === profileUID) {
      console.log("User is same as profile UID");
      setAddButton(false);
      setRemoveButton(false);
    }
  };

  useEffect(() => {
    if (user?.uid) {
      setLoading(true);
      checkCurrentUserPermission(user.uid);
      setLoading(false);
    }
  }, []);
  return (
    <>
      <div className="relative">
        {user?.uid && addButton && (
          <HrmButton
            className="absolute top-2 right-3"
            type="primary"
            text="Add asset"
            onClick={() => setShowModal(true)}
          />
        )}

        <div className="pt-20 container m-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 ">
          {readPermissionGiven ? (
            assets.length > 0 ? (
              assets.map((asset, i) => {
                return (
                  <div
                    className="shadow-lg rounded-xl py-6 px-8 w-full max-w-xl relative flex flex-col items-center"
                    key={i}
                  >
                    <div className="text-lg flex flex-col gap-4">
                      <div className="text-xl font-bold">{asset.asset}</div>
                      <div className="grid grid-cols-2 font-bold gap-2">
                        <div className="">Assigned by-</div>
                        <div className="whitespace-normal text-right" key={i}>
                          {asset.assigned_by_name}
                        </div>
                        <div className="">Assigned on-</div>
                        <div className="whitespace-normal text-right" key={i}>
                          {moment(asset.assigned_on).format("DD-MM-YYYY")}
                        </div>
                        <div className="">Assignment time-</div>
                        <div className="whitespace-normal text-right" key={i}>
                          {moment(asset.assigned_on).format("hh:mm A")}
                        </div>
                      </div>
                    </div>

                    {/* Button to Remove the asset */}
                    {showRemoveButton === true ? (
                      <div>
                        {asset.status === "ASSIGNED" ? (
                          <button
                            className="m-2 py-2 px-4 rounded-md text-white font-semibold bg-hrmRed"
                            onClick={() => {
                              patchAsset({
                                asset_id: asset.id!,
                                profileUID: profileUID,
                                status: "REMOVED",
                              });
                            }}
                          >
                            Unassign
                          </button>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    ) : (
                      <div className="pb-2"></div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="font-semibold text-xl text-gray-400">
                No assets present
              </div>
            )
          ) : (
            <div className="font-semibold text-xl text-gray-400">
              Read Permission Not Given
            </div>
          )}
        </div>
        {user?.uid && showModal && (
          <HrmPopup>
            <AddAssetModal
              onSelect={(data) => {}}
              asset=""
              close={() => setShowModal(false)}
              postAssetData={(emp_id: string, asset: string) => {
                postAsset(profileUID, asset);
              }}
            />
          </HrmPopup>
        )}
      </div>
    </>
  );
}

interface AddAssetProps {
  onSelect: (data: String) => void;
  asset: any;
  close: () => void;
  postAssetData: (emp_id: string, asset: string) => void;
}

const AddAssetModal: FunctionComponent<AddAssetProps> = ({
  onSelect,
  asset,
  close,
  postAssetData,
}) => {
  const { showToast } = useToast();
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] = useState<string | any>("");
  return (
    <div className="w-11/12 lg:w-1/3 bg-white pt-20 pb-12 px-8 rounded-lg shadow-lg relative">
      {/* X - close the MODAL */}
      <div className="absolute top-3 right-3 cursor-pointer">
        <X size={28} onClick={() => close()} />
      </div>
      <AssetSearchWidget
        placeholder={asset === "" ? "Selected Asset" : asset}
        onSelect={(val) => {
          setSelectedAsset(val);
        }}
      />
      <div className="flex flex-col items-center mt-12">
        {loading ? (
          <HrmLoader className="w-12 h-12" />
        ) : (
          <HrmButton
            type="primary"
            text="Submit"
            onClick={() => {
              if (selectedAsset === "") {
                showToast({
                  type: ToastType.error,
                  text: "Please select an asset",
                });
                return;
              } else {
                postAssetData(user?.uid!, selectedAsset);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Assets;
