import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import {
  GetOpsTicketsApi,
  TaskFilterModel,
} from "../../../../service/repos/ops/ops_tickets_repo";
import { getComplaints } from "../../../../service/repos/support/complaint_repo";
import { UTCToLocalDate } from "../../../../utils/date_util";
import LoadingWidget from "../../../components/loading_spinner";
import StadiumButton from "../../../new_components/common/stadium_button";

interface ExportTaskProps {
  filter: TaskFilterModel;
}

const ExportTask: FunctionComponent<ExportTaskProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const [fromDate, setFromdate] = useState<Date | null>();
  const [toDate, setTodate] = useState<Date | null>();

  const getOrders = async () => {
    if (!fromDate || !toDate) {
      alert("Please select date");
      return;
    } else if (moment(fromDate).isAfter(moment(toDate))) {
      alert("From date should not be greater than to date");
      return;
    }

    setLoading(true);

    // Fetch data from the backend API
    const res = await GetOpsTicketsApi({
      all: true,
      ...props.filter,
      fromDate,
      toDate,
    });

    if (res.success) {
      // Add address fields to each order
      const dataToExport = res.data.data.map((d: any) => {
        delete d.attachments;
        const address = d.address || {};

        // Combine all address fields into a single field
        const combinedAddress = address.full_address || "";

        return {
          ...d,
          address: combinedAddress, // Single field for address
        };
      });

      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: "Tasks",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };

      const csvExporter = new ExportToCsv(options);

      // Export the data
      csvExporter.generateCsv(dataToExport);
    }

    setLoading(false);
  };

  // const getOrders = async () => {
  //   if (!fromDate || !toDate) {
  //     alert("Please select date");

  //     return;
  //   } else if (moment(fromDate).isAfter(moment(toDate))) {
  //     alert("from date show not be greater than to date");

  //     return;
  //   }
  //   setLoading(true);
  //   const res = await GetOpsTicketsApi({
  //     all: true,
  //     ...props.filter,
  //     fromDate,
  //     toDate,
  //   });

  //   if (res.success) {
  //     const options = {
  //       fieldSeparator: ",",
  //       quoteStrings: '"',
  //       decimalSeparator: ".",
  //       showLabels: true,
  //       showTitle: true,
  //       title: "Tasks",
  //       useTextFile: false,
  //       useBom: true,
  //       useKeysAsHeaders: true,
  //       // headers: [
  //       //   "Name",
  //       //   "Dept",
  //       //   "Earned leave av.",
  //       //   "Earned leave taken",
  //       //   "Current month taken",
  //       //   "Medical leave av.",
  //       //   "Medical leave taken",
  //       //   "Current month taken",
  //       //   "Unpaid leave",
  //       // ],
  //     };

  //     const csvExporter = new ExportToCsv(options);

  //     csvExporter.generateCsv(
  //       res.data.data.map((d: any) => {
  //         delete d.attachments;
  //         return d;
  //       })
  //     );
  //   }
  //   setLoading(false);
  // };
  useEffect(() => {
    if (props.filter.fromDate && props.filter.toDate) {
      setFromdate(props.filter.fromDate);
      setTodate(props.filter.toDate);
    }
  });
  return (
    <div className="p-5">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="">
          <label htmlFor="from-date">From</label>
          <input
            id="from-date"
            className="border rounded p-2 w-full"
            type="date"
            name="fromDate"
            placeholder="From"
            required
            value={fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""}
            onChange={(e) => {
              if (
                toDate &&
                moment(e.target.valueAsDate).isAfter(moment(toDate))
              ) {
                alert("from date should not be after to date");
                return;
              }
              setFromdate(UTCToLocalDate(e.target.value));
            }}
          />
        </div>
        <div className="">
          <label htmlFor="to-date">To</label>
          <input
            id="to-date"
            className="border rounded p-2 w-full"
            type="date"
            name="toDate"
            placeholder="To"
            required
            value={toDate ? moment(toDate).format("YYYY-MM-DD") : ""}
            min={fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""}
            onChange={(e) => {
              if (
                fromDate &&
                moment(fromDate).isAfter(
                  moment(e.target.valueAsDate, "YYYY-MM-DD")
                )
              ) {
                alert("to date should  be after from date");
                return;
              }
              setTodate(UTCToLocalDate(e.target.value));
            }}
          />
        </div>
      </div>
      <div className="flex justify-end my-2">
        <StadiumButton type="submit" onTap={getOrders}>
          Export
        </StadiumButton>
      </div>
      <LoadingWidget loading={loading} />
    </div>
  );
};

export default ExportTask;
