import { mdiClose, mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import { useModal } from "../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../procurement/components/create_view/form_field";
import TableView from "../../../procurement/components/table_comp";
import { fetchGet, toQueryString } from "../../../service/api_client";
import SearchFieldWidget from "../../../ui/new_components/search_field";
import { currencyFormat } from "../../../utils/orders_utils";
import { urls } from "../../../utils/urls";
import { StockItemModel } from "../models/inventory_voucher";
import moment from "moment";
import { UTCToLocalDate } from "../../../utils/date_util";
import { ItemGroupModel } from "../models/item_group_model";
import ItemGroupSearchField from "../item_group/item_group_search_field";

interface StockItemListScreenProps {}

const StockItemListScreen: FunctionComponent<StockItemListScreenProps> = () => {
  const navaigation = useHistory();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/stocks`;
  const { showModal, onClose } = useModal();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<StockItemModel[]>([]);
  const [search, setSearch] = useState("");
  const [group, setgroup] = useState<ItemGroupModel>();
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  // useEffect(() => {
  //   const now = new Date();
  //   let from = new Date(new Date(now).setHours(0, 0, 0, 0));
  //   from = new Date(from.setDate(1));
  //   let to = new Date(new Date(now).setHours(23, 59, 59, 999));
  //   to = new Date(to.setMonth(now.getMonth() + 1));
  //   to = new Date(to.setDate(0));
  //   setFromDate(from);
  //   setToDate(to);
  // }, []);
  const fetchData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
    fromDate?: Date;
    toDate?: Date;
    group_id?: string;
  }) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData({ page, count, search, fromDate, toDate, group_id: group?.id });
  }, [page, search, fromDate, toDate, group]);
  // useEffect(() => {
  //   if (search) fetchData({ page, count, search });
  // }, [search]);
  // return <LedgerCreateScreen />;

  const onSelect = (d: StockItemModel) => {
    navaigation.push(urls.stock_items + "/" + d.item_id);
  };
  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex justify-between items-end my-1 "
        >
          <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3 ">
            <p className="border-l-4 border-myPrimaryColor pl-2">Stock Items</p>
            <button
              title="Refersh"
              onClick={() =>
                fetchData({
                  page,
                  count,
                  search,
                  fromDate,
                  toDate,
                  group_id: group?.id,
                })
              }
            >
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>
          <div className="flex justify-end   text-sm items-center gap-1 ">
            <div className="text-sm flex items-center gap-2">
              <div className="bg-white rounded w-52 flex gap-1">
                <ItemGroupSearchField
                  value={group}
                  placeholder="Filter by group"
                  onSelect={function (data: ItemGroupModel): void {
                    setgroup(data);
                  }}
                />
                {group && (
                  <button className="p-1" onClick={() => setgroup(undefined)}>
                    <Icon path={mdiClose} size={0.8}></Icon>
                  </button>
                )}
              </div>

              {/* <div className="flex items-center  gap-1">
                <label htmlFor="fromDate" className="">
                  From
                </label>
                <input
                  type="date"
                  name=""
                  id="fromDate"
                  className="p-1 rounded "
                  value={fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""}
                  onChange={(e) => {
                    const now = UTCToLocalDate(e.target.value)!;
                    const from = new Date(new Date(now).setHours(0, 0, 0, 0));

                    setFromDate(from);
                  }}
                />
              </div> */}
              <div className="flex items-center  gap-1">
                <label htmlFor="toDate" className="">
                  As On
                </label>
                <input
                  type="date"
                  name=""
                  id="toDate"
                  className="p-1 rounded "
                  value={toDate ? moment(toDate).format("YYYY-MM-DD") : ""}
                  onChange={(e) => {
                    const now = UTCToLocalDate(e.target.value)!;
                    const to = new Date(
                      new Date(now).setHours(23, 59, 59, 999)
                    );
                    setToDate(to);
                  }}
                />
              </div>
            </div>
            <SearchFieldWidget
              defaulValue={search}
              onClear={() => {
                setSearch("");
                // fetchData({ page: 1, count });
              }}
              onSubmit={(val) => {
                fetchData({ page: 1, count, search: val });
                setPage(1);
                // setSearch("");

                // setTimeout(() => {
                //   setSearch(val);
                // }, 10);
              }}
            />
            {/* <div
              onClick={() => {
                showModal({
                  title: `Add Ledger`,
                  type: ModalType.modal,
                  container(id) {
                    return (
                      <LedgerCreateScreen
                        onClose={(d) => {
                          if (d)
                            setData((o) => {
                              o.pop();
                              return [d, ...o];
                            });
                          setTotal((o) => o + 1);
                          onClose(id);
                        }}
                      />
                    );
                  },
                });
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
            </div> */}
          </div>
        </section>{" "}
        <TableView
          headers={[
            {
              key: "group",
              title: "Group",
              type: FieldType.long_string,
            },
            {
              key: "item_id",
              title: "Item",
              type: FieldType.long_string,
            },
            {
              key: "unit_no",
              title: "Closing stock unit",
              type: FieldType.number,
            },
            {
              key: "price",
              title: "Price",
              type: FieldType.currency,
            },
            {
              key: "",
              title: "Closing stock value",
              type: FieldType.currency,
            },
          ]}
          rows={data.map((data, i) => {
            const closing_value =
              data.total_inward_qty < 1
                ? 0
                : (data.total_inward_value * data.closing_qty) /
                  data.total_inward_qty;
            return {
              data: data,
              values: [
                data?.item?.group?.name,
                data?.item?.name,
                data?.closing_qty + " " + data?.item?.unit?.symbol,

                currencyFormat(closing_value / data.closing_qty),
                currencyFormat(closing_value),
              ],
            };
          })}
          page={page}
          total={total}
          count={count}
          itemCount={data.length}
          onPageChange={setPage}
          onClick={onSelect}
        />
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default StockItemListScreen;
